.body-container {
    display: flex;
    width: 100%;
    height: 100%;
    .left-bar {
        width: 15.625rem;
        height: 100%;
        transition: all 0.3s;
    }
    .right-bar {
        width: calc(100% - 15.625rem);
        height: 100%;
        transition: all 0.3s;
        header {
            height: 6.25rem;
            width: 100%;
            .head-bar {
                width: 100%;
                height: 100%;
                padding: 0.9375rem 1.875rem 0 1.875rem;
                .nav-header {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    .header-left {
                        display: flex;
                        align-items: center;
                        h1 {
                            font-size: 2.375rem;
                            color: #000;
                            font-weight: 400;
                            line-height: 6.25rem;
                        }
                    }
                    .header-right {
                        display: flex;
                        align-items: center;
                        .search-box {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 21.25rem;
                            height: 3.5rem;
                            border-radius: 0.9375rem;
                            background: rgba(255, 255, 255, 0.4);
                            border: 0.0625rem solid rgba(255, 255, 255, 0.5);
                            box-shadow: 0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.05);
                            border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
                            border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
                            padding: 0 0.9375rem;
                            .search {
                                width: 15.625rem;
                                border: 0;
                                background: rgba(255, 255, 255, 0.4);
                                &:focus {
                                    outline: none;
                                }
                            }
                            .search-icon {
                                width: 1.875rem;
                                height: 1.875rem;
                            }
                        }
                        .action-bar {
                            display: flex;
                            align-items: center;
                            li {
                                padding: 0 0 0 1rem;
                                display: flex;
                                align-items: center;
                                a {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 3.625rem;
                                    height: 3.625rem;
                                }
                            }
                        }
                        .generate {
                            width: 12.5rem;
                            height: 3.5rem;
                            border-radius: 1.125rem;
                            margin: 0 0 0 1rem;
                            border: 0.0625rem solid #5bcfc5;
                            background-color: #5bcfc5;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 1.25rem;

                            color: #fff;
                        }
                        .profile {
                            // width: 8.125rem;
                            // height: 70px;
                            // margin: 0 0 20px;
                            border: 0.0625rem solid #f5f5f5;
                            border-radius: 1rem;
                            // padding: 0.625rem 0.9375rem;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .left {
                                display: flex;
                                align-items: center;
                                .img-box {
                                    // width: 60px;
                                    // height: 60px;
                                    // border-radius: 50%;
                                    // // border: 1px solid #5bcfc5;
                                    // display: flex;
                                    // justify-content: center;
                                    // align-items: center;
                                    // overflow: hidden;
                                    // background: linear-gradient(45deg, #bb646e, #f88e91);
                                    img {
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                    p {
                                        color: #bb646e;
                                        font-size: 20px;
                                        // font-size: .8125rem;
                                        font-weight: 600;
                                        padding: 0 5px;
                                    }
                                }

                                .user-text {
                                    // margin-left: 1rem;
                                    // height: 3.125rem;
                                    padding: 0 0.625rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    p {
                                        color: #f88e91;
                                        font-size: 20px;
                                        font-weight: 600;
                                    }
                                    h6 {
                                        font-size: 20px;
                                        color: #bb646e;
                                        font-weight: 600;
                                        margin: 0 10px;
                                    }
                                }
                            }
                            .logout {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
        .page-contain {
            width: 100%;
            height: calc(100% - 6.25rem);
            overflow-y: scroll;
            padding: 0.9375rem 0;
        }
    }
    .reduce-left {
        width: 6.25rem !important;
        .tab-box {
            .tab {
                .header {
                    h1 {
                        display: none !important;
                    }
                }
                .tab-list {
                    padding: 0 0.3125rem !important;
                    p {
                        display: none !important;
                    }
                    .right {
                        display: none !important;
                    }
                }
            }
        }
    }
    .reduce-right {
        width: calc(100% - 6.25rem) !important;
    }
}
