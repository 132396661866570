
.mask{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    z-index: 15;
    .load{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .img{
            width: 12.5rem;
            height: 12.5rem;
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

.loading-show{
    display: block !important;
}