.home-content {
  .total-row {
    display: flex;
    align-items: center;
    padding: 0 20px;
    // justify-content: space-around;
    .right {
      display: flex;
      .card {
        width: 250px;
        height: 120px;
        border-radius: 1.75rem;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
        .after {
          position: absolute;
          height: 7.5rem;
          width: 7.5rem;
          transform: rotate(40deg);
          top: -2.1875rem;
          right: -2.1875rem;
          border-radius: 5px;
          background: -moz-linear-gradient(
            left,
            rgba(255, 255, 255, 0.15) 0,
            rgba(255, 255, 255, 0) 100%
          );
          background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0.15) 0,
            rgba(255, 255, 255, 0) 100%
          );
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.15) 0,
            rgba(255, 255, 255, 0) 100%
          );
        }
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3.75rem;
          height: 3.75rem;
          line-height: 3.375rem;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.4);
          .icon-suc {
            width: 2rem;
            height: 2rem;
          }
        }
        .txt-box {
          margin-left: 1.25rem;
          h2 {
            color: #fff;
            margin: 0 0 0.3125rem;
            font-size: 2.375rem;
            font-weight: normal;
            height: 2.8125rem;
          }
          p {
            font-size: 20px;
            line-height: 1.5rem;
            color: #fff;
          }
        }
      }
      .success {
        background-color: #62e0ac;
      }
      .fail {
        background-color: #f79292;
        margin-left: 50px;
      }
    }
    .left {
      display: flex;
      margin-left: 50px;
      .css-13cymwt-control {
        width: 250px;
        height: 120px;
        border-radius: 1.75rem;
        padding: 40px 20px;
        background: linear-gradient(45deg, #db858f, #f88185);
        box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      }
      .css-1jqq78o-placeholder {
        color: #fff;
        font-size: 20px;
      }
      .css-1xc3v61-indicatorContainer {
        color: #fff;
      }
      .css-1dimb5e-singleValue {
        color: #fff;
        font-size: 20px;
      }
      .css-t3ipsp-control {
        width: 250px;
        height: 120px;
        border-radius: 1.75rem;
        padding: 40px 20px;
        background: linear-gradient(45deg, #db858f, #f88185);
        box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      }
      .css-1u9des2-indicatorSeparator {
        color: #fff;
      }
      .css-15lsz6c-indicatorContainer {
        color: #fff;
      }

      .datePicker {
        margin-left: 50px;
        width: 400px;
        height: 120px;
        border-radius: 1.75rem;
        padding: 40px 20px;
        font-size: 20px;
        background: linear-gradient(45deg, #db858f, #f88185);
        box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
        .semi-datepicker-range-input {
          background-color: transparent;
          height: 100%;
          &:hover {
            background-color: transparent;
          }
        }
        .semi-datepicker-range-input {
          background-color: transparent;
        }
        .semi-datepicker-range-input-wrapper:hover {
          background-color: transparent;
        }
        .semi-datepicker-range-input-separator-active {
          color: #fff;
        }
        .semi-datepicker-range-input-suffix,
        .semi-datepicker-range-input-clearbtn {
          color: #fff;
        }
        input {
          // font-size: 15px;
          color: #fff;
          &::placeholder {
            color: #6c6e6e;
          }
          &:hover {
            background-color: transparent;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .css-dev-only-do-not-override-1qfezbu {
        color: #fff;
        font-size: 20px;
      }
      .ant-picker-suffix {
        color: #fff;
      }
      .ant-picker-clear {
        color: #fff;
      }
      .ant-picker-separator {
        color: #fff;
      }
      .ant-picker-input {
        input {
          color: #fff;
          font-size: 20px;
          &::placeholder {
            color: #fff;
          }
        }
      }
    }
  }
  .chart-row {
    margin: 20px 0 0;
    display: flex;
    .chart-content {
      width: 100%;
      height: 600px;
      padding: 20px;
      .chart-box {
        width: 100%;
        height: 100%;
        border-radius: 25px;
        background-color: #fff;
        box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      }
    }
  }
}

