.tab-box {
    width: 100%;
    height: 100%;
    padding: .625rem;
    .tab {
        height: 100%;
        width: 100%;
        border-radius: 25px;
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
        transition: all 0.2s ease;
        .header{
            height: 5.625rem;
            padding: 1.25rem .9375rem 0 .9375rem;
            display: flex;
            align-items: center;
            justify-content: center;
            h1{
                font-size: 20px;
                padding: 0 .9375rem;
                color: #df5555;
            }
        }
        .tab-list{
            padding: 0 .9375rem;
            li{
                width: 100%;
                height: 3.75rem;
                border-radius: 1.5625rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 1.25rem;
                color: #9fa4a6;
                font-size: 1.125rem;
                &:hover{
                    transform: scale(1.05,1.05);
                    transition: all .3s;
                }
            }
            .active{
                background: linear-gradient(45deg, #9a444e, #e06267);
                color: #fff;
            }
        }
    }
}
