.login-container {
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #211136, #bf5853);
    display: flex;
    justify-content: center;
    align-items: center;
    .login-box {
        width: 61.25rem;
        min-height: 31.25rem;
        border-radius: 0.625rem;
        font-family: "neo";
        overflow: hidden;
        box-shadow: 0rem 0rem 7.5rem rgba(0, 0, 0, 0.25);
        position: fixed;
        top: 50%;
        right: 50%;
        margin-top: -15.625rem;
        margin-right: -30.625rem;
        display: flex;
        .login-left {
            width: 50%;
            background: linear-gradient(45deg, #221334, #6c3049);
            position: relative;
            span {
                position: absolute;
                display: block;
            }
            .circle {
                width: 12.5rem;
                height: 12.5rem;
                border-radius: 50%;
                background: linear-gradient(45deg, #df5555, #ef907a);
                top: 4.375rem;
                left: 50%;
                margin-left: -6.25rem;
                overflow: hidden;
                span {
                    width: 9.375rem;
                    height: 2.5rem;
                    border-radius: 3.125rem;
                    position: absolute;
                    &:nth-child(1) {
                        top: 1.875rem;
                        left: -2.375rem;
                        background: #c55c59;
                    }
                    &:nth-child(2) {
                        bottom: 1.25rem;
                        right: -2.1875rem;
                        background: #934555;
                    }
                }
            }
            .star {
                span {
                    background: radial-gradient(#fff 10%, #fff 20%, rgba(72, 34, 64, 0));
                    border-radius: 50%;
                    box-shadow: 0 0 0.4375rem #fff;
                    &:nth-child(1) {
                        width: 0.9375rem;
                        height: 0.9375rem;
                        top: 3.125rem;
                        left: 1.875rem;
                    }
                    &:nth-child(2) {
                        width: 0.625rem;
                        height: 0.625rem;
                        left: 22.5rem;
                        top: 5rem;
                    }
                    &:nth-child(3) {
                        width: 0.3125rem;
                        height: 0.3125rem;
                        top: 25rem;
                        left: 5rem;
                    }
                    &:nth-child(4) {
                        width: 0.5rem;
                        height: 0.5rem;
                        top: 15rem;
                        left: 3.75rem;
                    }
                    &:nth-child(5) {
                        width: 0.25rem;
                        height: 0.25rem;
                        top: 1.25rem;
                        left: 12.5rem;
                    }
                    &:nth-child(6) {
                        width: 0.25rem;
                        height: 0.25rem;
                        top: 28.75rem;
                        left: 25.625rem;
                    }
                    &:nth-child(7) {
                        width: 0.375rem;
                        height: 0.375rem;
                        top: 15.625rem;
                        left: 21.875rem;
                    }
                }
            }
            .fly_star {
                span {
                    width: 5.625rem;
                    height: 0.1875rem;
                    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.67), rgba(255, 255, 255, 0));
                    background: -o-linear-gradient(left, rgba(255, 255, 255, 0.67), rgba(255, 255, 255, 0));
                    background: linear-gradient(to right, rgba(255, 255, 255, 0.67), rgba(255, 255, 255, 0));
                    transform: rotate(-45deg);
                    &:nth-child(1) {
                        top: 3.75rem;
                        left: 5rem;
                    }
                    &:nth-child(2) {
                        top: 13.125rem;
                        left: 20.75rem;
                        opacity: 0.6;
                    }
                }
            }
            .title {
                text-align: center;
                color: #af4b55;
                font-weight: 600;
                margin-top: 22.8125rem;
                font-size: 1.5625rem;
                font-family: Lora;
                display: flex;
                padding: 0 6.25rem;
                justify-content: space-around;
            }
        }
        .login-right {
            width: 50%;
            background: linear-gradient(45deg, #9a444e, #e06267);
            padding: 2.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .title {
                color: #fff;
                font-size: 1.875rem;
                text-align: center;
                margin-bottom: 2.5rem;
            }
            .row {
                display: flex;
                justify-content: center;
                input {
                    width: 300px;
                    height: 30px;
                    font-size: 18px;
                    background: none;
                    border: none;
                    border-bottom: 1px solid #fff;
                    color: #fff;
                    margin-bottom: 20px;
                    padding: 0.625rem 0;                    
                    &::placeholder {
                        color: #fabfbf;
                        font-weight: 200;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
            .code-row {
                display: flex;
                align-items: center;
                input {
                    width: 100px;
                    height: 30px;
                    font-size: 18px;
                    background: none;
                    border: none;
                    border-bottom: 1px solid #fff;
                    color: #fff;
                    margin-bottom: 20px;
                    padding: 0.625rem 0;                    
                    &::placeholder {
                        color: #fabfbf;
                        font-weight: 200;
                    }
                    &:focus {
                        outline: none;
                    }
                }
                .btn {
                    margin-left: 120px;
                    padding: 5px 10px;
                    font-size: 12px;
                    border: 1px solid #fff;
                    color: #fff;
                    text-align: center;
                    &:hover {
                        background-color: #fff;
                        color: #af4b55;
                    }
                }
            }
            .btn-row {
                margin-top: 2.5rem;
                display: flex;
                justify-content: center;
                .btn {
                    width: 12.5rem;
                    border: 1px solid #fff;
                    padding: 0.625rem 1.25rem;
                    color: #fff;
                    text-align: center;
                    &:hover {
                        background-color: #fff;
                        color: #af4b55;
                    }
                }
            }
        }
    }
}
