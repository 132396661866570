.file-contain {
    height: 100%;
    .card {
        margin: 0 1.25rem 0 0.625rem;
        margin-bottom: 1.875rem;
        background-color: #fff;
        transition: all 0.5s ease-in-out;
        position: relative;
        border: 0px solid transparent;
        border-radius: 1.25rem;
        box-shadow: 0px 12px 23px 0px rgba(62, 63, 122, 0.04);
        overflow: hidden;
        .header {
            height: 5.625rem;
            background: linear-gradient(45deg, #bb646e, #f88e91);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 1.875rem;
            .left {
                display: flex;
                align-items: center;
                .state-box {
                    margin-right: 1.25rem;
                    .control {
                        width: 120px;
                        border: 0px;
                        .css-13cymwt-control {
                            border-radius: 0.9375rem;
                            background: rgba(255, 255, 255, 0.4);
                            border: 0.0625rem solid rgba(255, 255, 255, 0.5);
                            box-shadow: 0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.05);
                            border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
                            border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
                        }
                        .css-1jqq78o-placeholder {
                            color: #fff;
                        }
                        .css-qbdosj-Input {
                            input {
                                color: #fff;
                            }

                            &:focus {
                                outline: none;
                            }
                        }
                        // .css-1dimb5e-singleValue {
                        //     color: #fff;
                        // }
                    }
                }
                .search-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 150px;
                    // height: 3.5rem;
                    height: 40px;
                    border-radius: 0.9375rem;
                    background: rgba(255, 255, 255, 0.4);
                    border: 0.0625rem solid rgba(255, 255, 255, 0.5);
                    box-shadow: 0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.05);
                    border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
                    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
                    padding: 0 0.9375rem;
                    .search {
                        width: 100px;
                        border: 0;
                        background: none;
                        color: #fff;
                        &:focus {
                            outline: none;
                        }
                        &::placeholder {
                            color: #fff;
                        }
                    }
                    .search-icon {
                        width: 1.875rem;
                        height: 1.875rem;
                    }
                }
            }
        }
        .contain {
            min-height: 40.625rem;
            padding: 0 1.25rem;
            .header-list {
                display: flex;
                align-items: center;
                padding: 0.75rem 1.25rem;
                justify-content: space-between;
                border-bottom: 0.0625rem solid #f0f1f5;
                li {
                    color: #bb646e;
                    font-size: 1.375rem;
                    text-align: center;
                    line-height: 3.75rem;
                    font-weight: 600;
                }
                .left {
                    display: flex;
                    align-items: center;
                    .time {
                        width: 12.5rem;
                    }
                    .email {
                        width: 300px;
                    }
                    .status {
                        width: 9.375rem;
                    }
                }
                .right {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .operation {
                        width: 9.375rem;
                    }
                }
            }
            .list {
                border-top: 1px solid #f0f1f5;
                display: flex;
                align-items: center;
                padding: 0.75rem 1.25rem;
                justify-content: space-between;
                li {
                    color: #7e7e7e;
                    line-height: 3.75rem;
                    font-size: 1.125rem;
                    text-align: center;
                    font-weight: 600;
                }
                .left {
                    display: flex;
                    align-items: center;
                    .time {
                        width: 12.5rem;
                    }
                    .email {
                        width: 300px;
                    }
                    .status {
                        width: 9.375rem;
                        .off {
                            width: 60px;
                            height: 40px;
                            border-radius: 20px;
                            line-height: 40px;
                            margin: auto;
                            background-color: #fbe8ee;
                            color: #ea7a9a;
                        }
                        .on {
                            width: 60px;
                            height: 40px;
                            border-radius: 20px;
                            line-height: 40px;
                            margin: auto;
                            background-color: #e3f9e9;
                            color: #2bc155;
                        }
                    }
                }
                .right {
                    .operation {
                        width: 9.375rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .icon {
                            margin: 0 10px;
                            transition: all 0.3s;
                            &:hover {
                                transform: scale(1.5, 1.5);
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .none-container {
                height: 34.375rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    // 分页
    .pagination {
        display: flex;
        justify-content: center;
        .MuiPaginationItem-page.Mui-selected {
            background: #bb646e;
            border-color: #bb646e;
            box-shadow: 0 0.625rem 1.25rem 0 rgba(207, 91, 91, 0.2);
            color: #fff;
        }
    }
}

.email-mask {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 12;
    .mask {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: scroll;
        .email-box {
            min-width: 700px;
            height: 99%;
            background-color: #fff;
            border-radius: 1.25rem;
            overflow: hidden;
            .head {
                height: 60px;
                background: linear-gradient(45deg, #bb646e, #f88e91);
                padding: 0 20px;
                display: flex;
                align-items: center;
                font-size: 20px;
                color: #fff;
                justify-content: space-between;
                .icon {
                    transition: all 0.3s;
                    &:hover {
                        cursor: pointer;
                        transform: scale(1.5, 1.5);
                    }
                }
            }
            .content {
                padding: 20px 20px;
                max-width: 850px;
                .row {
                    span {
                        display: block;
                        margin: 0 0 5px;
                        color: #bb646e;
                        font-weight: 650;
                        font-size: 13px;
                    }
                    input {
                        // height: 50px;
                        padding: 10px 20px;
                        width: 100%;
                        border: 1px solid #bb646e;
                        border-radius: 5px;
                    }
                    margin: 0 0 12px;
                    .icon {
                        transition: all 0.3s;
                        &:hover {
                            transform: scale(1.5, 1.5);
                            cursor: pointer;
                        }
                    }
                }
                .button {
                    margin: 20px 0 0;
                    display: flex;
                    justify-content: center;
                    .icon {
                        transition: all 0.3s;
                        &:hover {
                            cursor: pointer;
                            transform: scale(1.5, 1.5);
                        }
                    }
                }
                .email-content-row {
                    max-height: 330px;
                    overflow-y: scroll;
                    display: none;
                }
                .icon-row {
                    display: flex;
                    align-items: center;
                    .reload {
                        margin: 0 20px;
                        .icon {
                            transition: all 0.3s;
                            &:hover {
                                transform: rotate(180deg);
                                cursor: pointer;
                            }
                        }
                    }
                }
                .button-row {
                    padding: 20px 0;
                    display: flex;
                    justify-content: center;
                    .icon {
                        transition: all 0.3s;
                        &:hover {
                            transform: scale(1.5, 1.5);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.show-email {
    display: block !important;
}

.show-file {
    display: block !important;
}
